<template>
  <div class="report-container">
    <div class="report-area">
      <h1>Sensor Report</h1>

      <div v-if="reportLoading">Loading...</div>


      <div v-else class="table-container">
        <table>
          <tr>
            <td>Sensor Name:</td>
            <td>{{ this.sensorName }}</td>
          </tr>
          <tr>
            <td>Time Frame:</td>
            <td>{{ this.periodName }} starting {{ this.formattedStartTime }}
            </td>
          </tr>
          <tr>
            <td>High Temp Events:</td>
            <td>{{ this.highTempCount }} over {{ this.highTempThreshold }}°F</td>
          </tr>
          <tr>
            <td>Low Temp Events:</td>
            <td>{{ this.lowTempCount }} under {{ this.lowTempThreshold }}°F</td>
          </tr>
        </table>
      </div>

      <div class="chart-container">
        <div v-if="chartLoading">
          <p>Loading Sensor Data...</p>
        </div>
        <Chart class="chart" v-if="!chartLoading" ref="chart" :chart-data="chartdata" :options="otherOptions"/>
      </div>
    </div>


  </div>
</template>

<script>

import Chart from "@/components/Chart.vue";
import moment from "moment";

export default {
  name: "ReportPage",
  props: [],
  components: {
    Chart

  },
  data() {
    // console.log('report', );
    return {
      reportLoading: true,
      chartLoading: true,
      report: null,
      sensorUuid: this.$route.params.id,
      currentData: [],

      sensorName: "-",
      periodName: 'week',
      formattedStartTime: '-',
      highTempCount: 0,
      lowTempCount: 0,
      highTempThreshold: 0,
      lowTempThreshold: 0,

      chartLow1: 33,
      chartLow2: 30,
      chartHigh1: 41,
      chartHigh2: 44,

      colors: [
        "#13293D",
        "#006494",
        "#247BA0",
        "#1B98E0",
        "#21897E",
        "#3BA99C",
        "#554971",
        "#63768D",
        "#F08700",
        "#AD5D4E",
        "#353535",
        "#561F37",
        "#72195A",
      ],

      otherOptions: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          intersect: false,
        },
        scales: {
          xAxes: [
            {
              type: "time",
              time: {
                unit: "hour",
                tooltipFormat: "MMM D, h:mm a",
                stepSize: 24,
                displayFormats: {
                  hour: "MM/DD"
                },
              },
              ticks: {
                maxTicksLimit: 14,
                min: this.report ? new Date(this.report.periodStartTimestamp * 1000).toISOString() : new Date().toISOString()
              }
            },
          ],
          yAxes: [
            {
              ticks: {
                // suggestedMin: 30,
                // suggestedMax: 40,
                // min: 30,
                // max: 50,
                // stepSize: 2
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      },
    };
  },
  computed: {
    high() {
      return Math.max(
        ...[].concat(
          ...this.currentData.map((item) => {
            return Object.values(item).filter(
              (item2) => typeof item2 === "number"
            );
          })
        )
      );
    },
    low() {
      return Math.min(
        ...[].concat(
          ...this.currentData.map((item) => {
            return Object.values(item).filter(
              (item2) => typeof item2 === "number"
            );
          })
        )
      );
    },
  },

  async mounted() {

    if (this.$route.params.reportTag === 'range') {
      console.log('Loading from range: ', this.$route.params.reportTag, this.$route.query)
      const start = this.$route.query.start;
      const end = this.$route.query.end;
      if (!start || !end) {
        console.log('Missing start or end query params')
        return;
      }
      await this.loadFromRange(this.$route.params.id, start, end)
    } else {
      await this.loadFromReportTimestamp(this.$route.params.reportTag);
    }



    // console.log('set chartData: ', this.chartdata);

    this.chartLoading = false;

  },
  // watch: {
  //   otherOptions: {
  //     handler(val) {
  //       if (this.$refs.chart) {
  //         console.log('renderChart', this.chartdata, this.otherOptions)
  //         this.$refs.chart.renderChart(
  //           this.chartdata,
  //           this.otherOptions
  //           // this.deviceType === "KDX" ? this.kdxOptions : this.otherOptions
  //         );
  //       }
  //     },
  //     deep: true,
  //   },
  // },
  methods: {

    async loadChartReadings(sensor, start, end) {

      this.otherOptions.scales.xAxes[0].ticks.min = moment(start).startOf('day').toDate().toISOString();


      const readings = await this.$store.dispatch("sensor/pullReadings", {
        id: sensor.id,
        from: start,
        to: end
      });


      this.chartLow1 = sensor.low
          ? sensor.low + 2
          : -97;
        this.chartLow2 = sensor.low ? sensor.low : -100;
        this.chartHigh1 = sensor.high
          ? sensor.high - 2
          : 97;
        this.chartHigh2 = sensor.high ? sensor.high : 100;

      this.currentData = readings;

      const values = ['min'];
      const allData = values.map((item, i, arr) => ({
        name: item,
        readings: this.currentData.map((cur) => ({
            createdAt: cur.createdAt,
            [item]: cur[item],
          })),
      }));

      this.chartdata = {
        datasets: allData.map((item, i) => {
          return {
            label: `${item.name === "min" ? "temp" : item.name}`,
            borderColor: (context) => {
              if (item.name !== "min") {
                return this.colors[i];
              } else {
                return this.chartGradient(context);
              }
            },
            backgroundColor: (context) => {
              if (item.name !== "min") {
                return this.colors[i];
              } else {
                return this.chartGradient(context);
              }
            },
            radius: 0,
            borderWidth: 2,
            fill: false,
            data: item.readings.map((item2) => {
              return {
                x: new Date(item2.createdAt),
                y: item2[item.name],
              };
            }),
          };
        })
      };
      return readings;
    },

    async loadFromRange(sensorUuid, start, end) {
      console.log('Loading from range...', sensorUuid, start, end);
      
      const sensor = await this.$store.dispatch("sensor/getSensorByUuid", {uuid: sensorUuid});

      this.sensorName = sensor.name;
      this.periodName = `${moment(end).diff(moment(start), 'days')} days`;
      this.formattedStartTime = moment(start).format('MM-DD-YYYY');

      this.highTempThreshold = sensor.high;
      this.lowTempThreshold = sensor.low;

      this.reportLoading = false;

      const readings = await this.loadChartReadings(sensor, new Date(start), new Date(end));

      const tempCounts = await getHighLowTempCounts(readings, sensor.high, sensor.low);
      this.highTempCount = tempCounts.numHigh;
      this.lowTempCount = tempCounts.numLow;

    },

    async loadFromReportTimestamp(timestamp) {
      const report = await this.$store.dispatch("sensor/getSensorReport", {
        sensorUuid: this.sensorUuid,
        periodStartTimestamp: timestamp
      });

      // console.log('report: ', report);

      this.sensorName = report.sensor.name;
      this.periodName = report.period;
      this.formattedStartTime = moment(report.periodStartTimestamp * 1000).format('MM-DD-YYYY');
      this.highTempCount = report.highTemps;
      this.lowTempCount = report.lowTemps;
      this.highTempThreshold = report.sensor.high;
      this.lowTempThreshold = report.sensor.low;
      this.reportLoading = false;
      
      await this.loadChartReadings(report.sensor, new Date(report.periodStartTimestamp*1000), moment(report.periodStartTimestamp * 1000).add(1, report.period).toDate());
    },



    chartGradient(context) {
      let gradient = null;
      let width = null;
      let height = null;
      let chartArea = context.chart.chartArea;
      if (!chartArea) {
        return null;
      }
      let chartWidth = chartArea.right - chartArea.left;
      let chartHeight = chartArea.bottom - chartArea.top;
      if (gradient === null || width !== chartWidth || height !== chartHeight) {
        width = chartWidth;
        height = chartHeight;
        let ctx = context.chart.ctx;
        gradient = ctx.createLinearGradient(
          0,
          chartArea.bottom,
          0,
          chartArea.top
        );

        let colors = this.colorStop();
        colors.forEach((item) => {
          gradient.addColorStop(item.value, item.color);
        });
      }
      return gradient;
    },

    colorStop(val) {
      let vals = [];

      // green
      if (this.high < this.chartHigh1) {
        vals.push({
          color: "green",
          value: 0,
        });
      }
      // yellow
      else if (this.low >= this.chartHigh1 && this.high <= this.chartHigh2) {
        vals.push({
          color: "#d8d300",
          value: 0,
        });
      }
      // red
      else if (this.low > this.chartHigh2) {
        vals.push({
          color: "red",
          value: 0,
        });
      }
      // green, yellow and red
      else if (this.low < this.chartHigh1 && this.high >= this.chartHigh2) {
        vals.push(
          {
            color: "green",
            value: 0,
          },
          {
            color: "#d8d300",
            value:
              Math.abs(this.low - this.chartHigh1) / (this.high - this.low),
          },
          {
            color: "red",
            value:
              Math.abs(this.low - this.chartHigh2) / (this.high - this.low),
          }
        );
      }
      // green and yellow
      else if (
        this.low < this.chartHigh1 &&
        this.high >= this.chartHigh1 &&
        this.high < this.chartHigh2
      ) {
        vals.push(
          {
            color: "green",
            value: 0,
          },
          {
            color: "#d8d300",
            value:
              Math.abs(this.low - this.chartHigh1) / (this.high - this.low),
          }
        );
      }
      // yellow and red
      else if (this.low >= this.chartHigh1 && this.high >= this.chartHigh2) {
        vals.push(
          {
            color: "#d8d300",
            value: 0,
          },
          {
            color: "red",
            value:
              Math.abs(this.low - this.chartHigh2) / (this.high - this.low),
          }
        );
      }

      return vals;
    },


    
  },
};

const getHighLowTempCounts = (readings, highThreshold, lowThreshold) => {

  let isHigh = false;
  let isLow = false;
  let numHigh = 0;
  let numLow = 0;

  for (let ii=0; ii<readings.length; ii++) {

    if (isHigh && readings[ii].min < highThreshold) {
      isHigh = false;
    } else if (isLow && readings[ii].min > lowThreshold) {
      isLow = false;
    } else if (!isHigh && !isLow && readings[ii].min > highThreshold) {
      console.log('Starting high excursion: ', readings[ii].min, highThreshold);
      isHigh = true;
      numHigh += 1;
    } else if (!isLow && !isHigh && readings[ii].min < lowThreshold) {
      console.log('Starting low excursion: ', readings[ii].min, lowThreshold);
      isLow = true;
      numLow += 1;
    }
  }

  return {
    numHigh,
    numLow
  }
}

</script>

<style>
div.report-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
div.report-area {

  width: 75%;
}
div.chart-container {
  padding-top: 24px;
  padding-bottom: 24px;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.chart {
  margin-left: 24px;
  margin-right: 24px;
}
div.table-container {
  margin-top: 24px;
}

div.table-container table {
  width: 100%;
  border-collapse: collapse;
}
div.table-container p {
    padding: 12px;
  }

  div.table-container th, div.table-container td {
  padding: 18px;
  text-align: left;
  border: 1px solid #ddd;
}

div.table-container tr:nth-child(even) {
  background-color: #f2f2f2;
}
</style>
